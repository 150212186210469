$input-padding: 10px 15px;
$input-border-radius: 8px;
$input-border-color: #ccc;
$input-border-color-focus: #0099ff;
$input-box-shadow-focus: 0 0 8px rgba(0, 153, 255, 0.5);
$input-placeholder-color: #aaa;
$input-font-size: 16px;

.key-setter-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
}

.key-setter-title {
  font-size: 24px;
  margin-bottom: 20px;
}

.input-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.simple-input {
  width: 100%;
  max-width: 400px;
  padding: $input-padding;
  border: 1px solid $input-border-color;
  border-radius: $input-border-radius;
  font-size: $input-font-size;
  outline: none;
  transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;

  &:focus {
    border-color: $input-border-color-focus;
    box-shadow: $input-box-shadow-focus;
  }

  &::placeholder {
    color: $input-placeholder-color;
    opacity: 1;
  }

  &:hover {
    border-color: $input-border-color-focus;
  }
}

.set-key-button {
  margin-left: 10px;
  padding: $input-padding;
  border: none;
  border-radius: $input-border-radius;
  background-color: #0099ff;
  color: white;
  font-size: $input-font-size;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;

  &:hover {
    background-color: darken(#0099ff, 10%);
  }
}
